import * as React from "react";
import MaterialTable from "./MaterialTable";
import {
  Button,
  Select,
  Modal,
  Box,
  Typography,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import { TextField } from "../theme/vs-theme";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import moment from "moment";
import dotenv from "react-dotenv";
import SelectPathologyAndMedicalGroup from "./SelectPathologyAndMedicalGroup";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const AlertsTable = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  props = { ...props, user };
  const [alerts, setAlerts] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [selectedPathology, setSelectedPathology] = React.useState("");
  const [selectedMedicalGroup, setSelectedMedicalGroup] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [selectedAlerts, setSelectedAlerts] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({
    how_i_feel: true,
  });
  const [rowSelection, setRowSelection] = React.useState({});
  const [form, setForm] = React.useState({
    status: 0,
    comment: "",
  });
  const [showModal, setShowModal] = React.useState(false);
  const [openUpdateAlertsModal, setOpenUpdateAlertsModal] =
    React.useState(false);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = React.useMemo(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: t("name"),
        size: 100,
      },
      {
        id: "lastname",
        accessorKey: "lastname",
        header: t("lastname"),
        size: 100,
      },
      {
        id: "detail",
        accessorKey: "detail",
        header: t("detail"),
        size: 150,
        Cell: (cell) => (
          <>
            <p style={{ whiteSpace: "pre-line" }}>{cell.row.original.detail}</p>
          </>
        ),
      },
      {
        id: "how_i_feel",
        accessorKey: "how_i_feel",
        header: t("how_i_feel"),
        size: 150,
        Cell: (cell) => (
          <>
            {cell.row.original.how_i_feel ? (
              <img src={`/face${cell.row.original.how_i_feel}.png`} />
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        id: "datetime",
        accessorKey: "datetime",
        header: t("date_time"),
        size: 150,
        Cell: (cell) => (
          <>
            <p>{moment(cell.row.original.datetime).format("DD/M/yy HH:mm")}</p>
          </>
        ),
      },
      {
        id: "status",
        accessorKey: "status",
        header: t("state"),
        size: 50,
        Cell: (cell) => (
          <>
            {cell.row.original.status == 1 ? (
              <img src="/pending-status.png" />
            ) : cell.row.original.status == 2 ? (
              <img src="/in-process-status.png" />
            ) : (
              <img src="/finished-status.png" />
            )}
          </>
        ),
      },
      {
        id: "priority",
        accessorKey: "priority",
        header: t("priority"),
        size: 50,
        Cell: (cell) => (
          <>
            {cell.row.original.priority == 1 ? (
              <img src="/low-priority.png" />
            ) : (
              <img src="/high-priority.png" />
            )}
          </>
        ),
      },
      {
        id: "comment",
        accessorKey: "comment",
        header: t("comments"),
        size: 150,
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) => (
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/alerts/${cell.row.original.id}`, {
                  state: {
                    patient:
                      cell.row.original.name + " " + cell.row.original.lastname,
                  },
                })
              }
              src={"/update-gray.png"}
              alt={"Actualizar"}
            />
            &nbsp;&nbsp;&nbsp;
          </>
        ),
      },
    ],
    [navigate, t]
  );

  const modalStyle = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "566px",
      boxShadow:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);",
      borderRadius: "16px",
      background: "#FCFCFC",
    },
    container: {
      margin: "36px 26px",
    },
    title: {
      textAlign: "center",
      fontSize: "30px",
      color: "#3988E3",
      fontWeight: "700",
      lineHeight: "42px",
      letterSpacing: "0.75px",
    },
    select: {
      margin: "20px 0px",
      borderRadius: "12px",
      border: "1px solid #869AE8",
      color: "#869AE8",
    },
    buttons: {
      borderRadius: "15px",
      padding: "10px 24px",
      marginTop: "20px",
      color: "white",
    },
  };

  const buttonStyle = {
    borderRadius: "15px",
    background: "#3657D9",
    width: "275px",
  };

  const styles = {
    modal: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "761px",
      height: "265px",
      padding: "40px 31px 40px 31px",
      gap: "40px",
      borderRadius: "16px 16px 16px 16px",
      backgroundColor: "white",
      opacity: 1,
      boxShadow: 24,
      p: 4,
      zIndex: 1300,
    },
    titleModal: {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: "600",
      textAlign: "center",
      color: "#575565",
    },
    button: {
      width: "144px",
      height: "47px",
      color: "white",
      backgroundColor: "#B33A3A",
    },
    button2: {
      width: "144px",
      height: "47px",
      color: "white",
      backgroundColor: "#5EC360",
    },
    closeButton: {
      position: "absolute",
      top: 8,
      right: 8,
    },
  };

  const getAlerts = (pathologyId, medicalGroupId) => {
    if (
      (pathologyId != null && medicalGroupId != null && pathologyId != "") ||
      !props.fromAlertsPage
    ) {
      setAlerts([]);
      const fetchData = async () => {
        setIsLoading(true);
        setIsRefetching(true);
        setIsError(false);
        const url = new URL(props.endpoint, dotenv.API_URL);
        url.searchParams.set("page", `${pagination.pageIndex + 1}`);
        url.searchParams.set("size", `${pagination.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        url.searchParams.set("globalFilter", globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
        if (props.fromAlertsPage) {
          url.searchParams.set("pathologyId", pathologyId);
          url.searchParams.set("medicalGroupId", medicalGroupId);
        }
        axios
          .get(url.href, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            if (!props.fromAlertsPage) {
              let data = response.data[0].data
              if (data) {
                const sortedData = data.sort(
                  (a, b) => new Date(b.datetime) - new Date(a.datetime)
                );
                setAlerts(sortedData);
                setRowCount(data.total);
                if (response.data[1] == "ICC") {
                  setColumnVisibility({ how_i_feel: true })
                } else {
                  setColumnVisibility({ how_i_feel: false })
                }
              }
            } else {
              let data = response.data.data
              if (data) {
                const sortedData = data.sort(
                  (a, b) => new Date(b.datetime) - new Date(a.datetime)
                );
                setAlerts(sortedData);
                setRowCount(data.total);
              }
            }
            setIsError(false);
          })

          .catch(() => setIsError(true))
          .finally(() => {
            setIsLoading(false);
            setIsRefetching(false);
          });
      };
      fetchData();
    }
  };

  React.useEffect(() => {
    if (selectedPathology !== "" || !props.fromAlertsPage) {
      getAlerts(selectedPathology, selectedMedicalGroup);
    }
  }, [
    selectedMedicalGroup,
    columnFilters,
    globalFilter,
    pagination,
    sorting,
    openUpdateAlertsModal,
  ]);

  React.useEffect(() => {
    if (props.fromAlertsPage) {
      let state = selectedPathology == 1;
      setColumnVisibility({ how_i_feel: state });
    }
  }, [selectedPathology]);

  const updateAlertsModal = (rows) => {
    setSelectedAlerts(
      rows.map((row) => {
        return row.original.id;
      })
    );
    setOpenUpdateAlertsModal(true);
  };

  const handleClose = () => {
    setOpenUpdateAlertsModal(false);
  };

  const handleChange = (event) => {
    if (event.target.name == "comment" && form.status == 0) {
      setForm({ comment: event.target.value, status: 2 });
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  const updateAlerts = () => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsRefetching(true);
      setIsError(false);

      const url = new URL(
        "/institutions/v1/alerts/bulk-update",
        dotenv.API_URL
      );
      axios
        .post(
          url.href,
          {
            ids: selectedAlerts,
            status: form.status,
            comment: form.comment,
            doctor_id: user.user.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then(() => {
          setIsError(false);
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
          setForm({ status: 0, comment: "" });
          setOpenUpdateAlertsModal(false);
          getAlerts();
        });
    };
    fetchData();
  };

  const tableInstanceRef = React.useState({});

  const handleCloseModal = () => {
    setShowModal(false);
    setOpenUpdateAlertsModal(false);
  };

  const handleConfirmLeave = () => {
    setShowModal(false);
    updateAlerts();
  };
  const handleClosex = () => {
    setShowModal(false);
  };

  return (
    <>
      {props.fromAlertsPage ? (
        <Box marginBottom={"10px"}>
          <SelectPathologyAndMedicalGroup
            endpoint="alert"
            preferencePathology="alerts_panel_default_pathology"
            preferenceMedicalGroup="alerts_panel_default_medical_group"
            setSelectedPathology={setSelectedPathology}
            setSelectedMedicalGroup={setSelectedMedicalGroup}
            selectedPathology={selectedPathology}
            selectedMedicalGroup={selectedMedicalGroup}
            updateTable={getAlerts}
          />
        </Box>
      ) : (
        <></>
      )}
      <div>
        <MaterialTable
          columns={columns}
          data={alerts}
          title="Alerts"
          enableRowSelection
          onRowSelectionChange={setRowSelection}
          muiToolbarAlertBannerProps={
            isError
              ? {
                color: "error",
                children: t("error_fetching_data"),
              }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={rowCount}
          tableInstanceRef={tableInstanceRef}
          state={{
            columnVisibility: columnVisibility,
            rowSelection,
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting
          }}
        />
        <br />
        {!isLoading ? (
          <div align="right">
            <Button
              disabled={Object.keys(rowSelection).length === 0}
              sx={buttonStyle}
              variant="contained"
              onClick={() =>
                updateAlertsModal(
                  tableInstanceRef.current.getSelectedRowModel().rows
                )
              }
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <span>{t("update_alarm")}</span>
                <img src={"/update-white.png"} alt={"Actualizar"} />
              </div>
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Modal
        open={openUpdateAlertsModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle.modal}>
          <Box sx={modalStyle.container}>
            <Typography sx={modalStyle.title} id="modal-modal-title">
              {t("update_alarm")}
            </Typography>
            <Select
              sx={[modalStyle.select, { width: "205px" }]}
              name={"status"}
              onChange={handleChange}
              defaultValue={0}
              value={form.status}
            >
              <MenuItem disabled value={0}>
                <em>{t("state")}</em>
              </MenuItem>
              <MenuItem value={1}>{t("pending")}</MenuItem>
              <MenuItem value={2}>{t("in_process")}</MenuItem>
              <MenuItem value={3}>{t("finished")}</MenuItem>
            </Select>
            <TextField
              sx={{ width: "506px" }}
              minRows={3}
              multiline
              name={"comment"}
              placeholder={"Comentario"}
              onChange={handleChange}
            ></TextField>
            <Modal
              open={showModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styles.modal}>
                <IconButton sx={styles.closeButton} onClick={handleClosex}>
                  <CloseIcon />
                </IconButton>
                <Typography sx={styles.titleModal}>
                  {t("question_areyou")}
                </Typography>
                <Grid container spacing={2} justifyContent="center" mt={3}>
                  <Grid item>
                    <Button onClick={handleCloseModal} sx={styles.button}>
                      {t("no")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleConfirmLeave}
                      color="primary"
                      sx={styles.button2}
                    >
                      {t("yes")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <div align="right">
              <Button
                sx={[
                  modalStyle.buttons,
                  {
                    width: "144px",
                    marginRight: "16px",
                    backgroundColor: "#D04444",
                  },
                ]}
                onClick={() => setOpenUpdateAlertsModal(false)}
              >
                {t("cancel")}
              </Button>
              <Button
                sx={[
                  modalStyle.buttons,
                  { width: "187px", backgroundColor: "#5EC360" },
                ]}
                disabled={form.status == 0}
                onClick={() => {
                  if (!form.comment && form.status === 3) {
                    setShowModal(true);
                  } else {
                    updateAlerts();
                  }
                }}
              >
                {t("save")}
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AlertsTable;
