import {
  Box,
  MenuItem,
  Modal,
  Select,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import React from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../../../hooks/useAuth";
import SelectMedicine from "./SelectMedicine";
import AddMedicineRow from "./AddMedicineRow";
import withMessages from "../../../components/WithMessages"

const AddMedication = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [vademecum, setVademecum] = React.useState(props.vademecum);
  const [vademecums, setVademecums] = React.useState([]);
  const [selectedMedicines, setSelectedMedicines] = React.useState([]);
  const [isSelectingMedicine, setIsSelectingMedicine] = React.useState(false);
  const [medicationPlans, setMedicationPlans] = React.useState([]);

  const styles = {
    container: {
      borderRadius: "16px",
      background: "#FCFCFC",
      boxShadow:
        "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
      padding: "40px",
      width: "95%",
      maxHeight: "95%",
      overflowY: "auto",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    title: {
      color: "#3988E3",
      fontSize: "22px",
      fontWeight: 700,
    },
    legend: {
      marginTop: "20px",
      color: "#869AE8",
    },
    subtitle: {
      color: "#869AE8",
      fontSize: "14px",
      fontWeight: 500,
      marginTop: "30px",
      marginBottom: "10px",
    },
    select: {
      width: "100%",
      borderRadius: "12px",
      border: "1px solid #869AE8",
      background: "#FFF",
      color: "#869AE8",
      fontSize: "16px",
      fontWeight: 500,
      textAlign: "left",
    },
    drugs: {
      width: "279px",
      height: "Fixed (68px)px",
      padding: "12px 24px 12px 24px",
      gap: "12px",
    },
    schedule: {
      width: "80px",
      height: "68px",
      padding: "0",
      textAlign: "center",
    },
  };

  React.useEffect(() => {
    const url = new URL(
      "/institutions/v1/medicines/vademecums",
      dotenv.API_URL
    );
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        setVademecums(res.data.data);
      });
  }, []);

  React.useEffect(()=>{
    if (vademecum) {
      const url = new URL(
        "/institutions/v1/patients/" + props.patientId + "/parameters",
        dotenv.API_URL
      );
      axios
        .put(url.href, {'vademecum': vademecum}, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
    }
  }, [vademecum])

  const handleMedicationPlan = (medPlan, index) => {
    const updatedMedicationPlan = [...medicationPlans];

    if (index + 1 > updatedMedicationPlan.length) {
      updatedMedicationPlan.push(medPlan);
    } else {
      updatedMedicationPlan[index] = medPlan;
    }

    setMedicationPlans(updatedMedicationPlan);
  };

  const saveMedications = () => {
    const url = new URL(
      "/institutions/v1/medication-plans/bulk",
      dotenv.API_URL
    );
    const filteredMedicationPlans = medicationPlans.map(medication => ({
      ...medication,
      schedules: medication.schedules.filter(schedule => schedule.dose !== 0)
    }));

    axios
      .post(
        url.href,
        { medication_plans: filteredMedicationPlans, patient_id: props.patientId },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(() => {
        setMedicationPlans([]);
        setSelectedMedicines([]);
        props.showMessage(t("medication_loaded_succesfully"));
        props.handleClose();
      });
  };

  const checkDisabled = () => {

    const invalidPlans = medicationPlans.some(mP => {
      if (mP.frequency_type === 2) {
        const totalDose = mP.schedules.reduce((sum, scheduleItem) => sum + (scheduleItem.dose || 0), 0);
        return totalDose !== 1;
      }
      return false;
    });
  
    if (invalidPlans) {
      return true;
    }
    
    return medicationPlans.some(mP => mP.frequency_type == null) || medicationPlans.length == 0;
  };
  

  const handleSelectMedicine = (medicine) => {
    setSelectedMedicines([...selectedMedicines, medicine]);
  };

  const deleteMedication = (medicineId) => {
    const updatedMedicines = selectedMedicines.filter(
      (medicine) => medicine.id !== medicineId
    );
    setSelectedMedicines(updatedMedicines);
  };

  const handleCancel = () => {
    setSelectedMedicines([]);
    setMedicationPlans([]);
    props.handleClose();
  };

  return (
    <Modal open={props.open} onClose={props.handleClose} closeAfterTransition>
      <Box sx={styles.container}>
        <Box sx={styles.title}>{t("add_medications")}</Box>
        {selectedMedicines.length == 0 ? (
          <>
            <Box sx={styles.subtitle}>{t("vademecum_selection")}</Box>
            <Select
              sx={[styles.select]}
              name={"vademecum"}
              onChange={(sel) => setVademecum(sel.target.value)}
              defaultValue={0}
              value={vademecum}
            >
              {vademecums?.map((v) => (
                <MenuItem value={v.id} key={v.id}>
                  {v.name}
                </MenuItem>
              ))}
            </Select>
            <SelectMedicine
              vademecum={vademecum}
              selectMedicine={handleSelectMedicine}
            />
          </>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, overflowY: "auto" }}
                aria-label="caption table"
              >
                <TableHead>
                  <TableRow backgroundColor={"#EAECF0"}>
                    <TableCell padding="0"></TableCell>
                    <TableCell sx={styles.drugs}>{t("medicine")}</TableCell>
                    <TableCell alignItems="left">{t("how_much")}</TableCell>
                    <TableCell alignItems="left" sx={styles.schedule}>
                      6 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.schedule}>
                      8 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.schedule}>
                      10 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.schedule}>
                      12 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.schedule}>
                      14 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.schedule}>
                      16 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.schedule}>
                      18 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.schedule}>
                      20 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.schedule}>
                      22 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.schedule}>
                      24 hs
                    </TableCell>
                    <TableCell align="left" sx={styles.obser}>
                      {t("observ")}
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedMedicines.map((medicine, index) => (
                    <AddMedicineRow
                      updateMedicationPlan={(medPlan) =>
                        handleMedicationPlan(medPlan, index)
                      }
                      deleteMed={() => deleteMedication(medicine.id)}
                      selectedMedicine={medicine}
                      isSelectingMedicine={() => setIsSelectingMedicine(true)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {isSelectingMedicine ? (
              <SelectMedicine
                vademecum={vademecum}
                selectMedicine={handleSelectMedicine}
              />
            ) : (
              <></>
            )}
          </>
        )}
        <Typography sx={styles.legend}>{t("remember_you_cant")}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            mt: "5px",
            width: "474px",
            gap: "10px"
          }}
        >
          <Button
            sx={{ background: "#D04444", color: "#FCFCFC" }}
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>
          <Button
            disabled={checkDisabled()}
            onClick={saveMedications}
            sx={{
              backgroundColor: "#5EC360",
              color: "#FCFCFC",
            }}
          >
            {t("confirm")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default withMessages(AddMedication);
