import * as React from "react";
import MaterialTable from "../../components/MaterialTable";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { useTranslation } from "react-i18next";

const ImprovementIdeasIndexPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = React.useMemo(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: t("doctor_name"),
      },
      {
        id: "date",
        accessorKey: "date",
        header: "Fecha y hora",
      },
      {
        id: "pathologies",
        accessorKey: "pathologies",
        header: t("treated_pathologies"),
      },
      {
        id: "comment",
        accessorKey: "comment",
        header: t("comments"),
      },
      {
        id: "attachments",
        accessorKey: "attachments",
        header: t("attachments"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) =>
          cell.row.original.attachments.map((attachment) => (
            <a
              style={{ textDecoration: "none", marginLeft: "5px" }}
              href={attachment.file_path}
              target="_blank"
            >
              <img
                style={{ maxWidth: "70px", maxHeight: "50px" }}
                src="/chat_assets/attach.png"
              />
            </a>
          )),
      },
    ],
    [navigate, t]
  );

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsRefetching(true);
      setIsError(false);

      const url = new URL("/institutions/v1/improvement-ideas", dotenv.API_URL);

      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setData(response.data);
          setIsError(false);
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
        });
    };
    fetchData();
  }, [columnFilters, globalFilter, pagination, sorting, user]);

  return (
    <div>
      {["superadmin", "admin-vs"].includes(props.role) ? (
        <div>
          <MaterialTable
            columns={columns}
            data={data}
            title="Institutions"
            muiToolbarAlertBannerProps={
              isError
                ? {
                    color: "error",
                    children: t("error_fetching_data"),
                  }
                : undefined
            }
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            rowCount={rowCount}
            state={{
              columnFilters,
              globalFilter,
              isLoading,
              pagination,
              showAlertBanner: isError,
              showProgressBars: isRefetching,
              sorting,
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ImprovementIdeasIndexPage;
