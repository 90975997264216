import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Tab, Tabs } from "@mui/material";
import { useLanguage } from "../hooks/LanguageProvider";

const MainMenu = (props) => {
  const navigate = useNavigate();

  const { language, changeLanguage } = useLanguage();
  const { t } = useTranslation();
  // import js file dinamically using props.role + .js
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [menu, setMenu] = React.useState([]);

  React.useEffect(() => {
    const loadMenu = async () => {
      if (!props.role) return;
      const menuJson = await import(`../menus/${props.role}.js`);
      setMenu(menuJson.default);
    };
    loadMenu();
  }, [props.role]);

  const handleClick = (value) => {
    setSelectedIndex(value);
    let link = menu.find((item) => item.key === value).link ?? "/notfound";
    navigate(link);
  };

  const handleChange = (event, newValue) => {
    changeLanguage(newValue);
  };

  const hoverMenuStyle = {
    pt: 3,
    "&:hover, &:focus": {
      backgroundColor: "#DDEFFF",
      borderRadius: "18px",
    },
  };
  return (
    <List
      sx={{
        borderRadius: "24px",
        p: "8px",
        pt: 5,
        backgroundColor: "white",
        color: "#A5A7B6",
        minWidth: "200px",
        maxWidth: "250px",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          sx={{ display: { xs: "block", lg: "block", xl: "block" } }}
          id="nested-list-subheader"
        >
          <img
            src="/logo-completo.png"
            alt="logo"
            style={{ maxWidth: "200px", minWidth: "150px" }}
          />
          <Grid container alignItems="center" spacing={1} sx={{ mt: 1 }}>
            <Grid item>
              <img
                src="/world.png"
                alt="logoworld"
                style={{ verticalAlign: "middle" }}
              />
            </Grid>
            <Grid item>
              <Tabs
                value={language}
                onChange={handleChange}
                textColor="primary"
                centered
                sx={{ display: "flex" }}
              >
                <Tab
                  label="ES |"
                  value="es"
                  sx={{ minWidth: "auto", padding: 0, margin: "0 8px" }}
                />
                <Tab
                  label="EN "
                  value="en"
                  sx={{ minWidth: "auto", padding: 0, margin: "0 0px" }}
                />
                <Tab
                  label="| PT"
                  value="pt"
                  sx={{ minWidth: "auto", padding: 0, margin: "0 8px" }}
                />
              </Tabs>
            </Grid>
          </Grid>
        </ListSubheader>
      }
    >
      {menu.map((item) =>
        item.key == "surveys" && !props.showSurveys ? (
          <></>
        ) : (
          <ListItemButton
            key={item.key}
            sx={hoverMenuStyle}
            selected={selectedIndex === item.key}
            onClick={() => handleClick(item.key)}
            value={item.key}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={item.icon} alt={item.name} width={24} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              sx={{
                fontSize: 14,
                overflow: "hidden",
                textOverflow: "clip",
                whiteSpace: "nowrap",
                width: "100%",
              }}
              primary={t(item.name)}
            />
            {item.name == "Chat" && props.unseenMessages > 0 ? (
              <Box
                sx={{
                  width: 30,
                  height: 20,
                  color: "white",
                  textAlign: "center",
                  fontSize: 14,
                  background: "red",
                  borderRadius: 10,
                }}
              >
                {props.unseenMessages}
              </Box>
            ) : (
              <></>
            )}
          </ListItemButton>
        )
      )}
    </List>
  );
};

export default MainMenu;
