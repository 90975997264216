import * as React from 'react';
import withMessages from '../../components/WithMessages';
import CustomizeParametersWithPathology from '../../components/CustomizeParametersWithPathology';


const CustomizeDefaultParametersPage = (props) => {
  

  return (
    ['superadmin', 'admin-vs'].includes(props.role) ?
      <CustomizeParametersWithPathology
        endpoint = "params/pathology"
        withPathology = {true}
        showMessage={props.showMessage}
      >        
      </CustomizeParametersWithPathology>
    :<></>
  );
}

export default withMessages(CustomizeDefaultParametersPage);
