import * as React from "react";
import {
  Paper,
  Box,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import dotenv from "react-dotenv";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import AddMedication from "./AddMedication";
import axios from "axios";

const MedicationPlanData = (props) => {
  const { t } = useTranslation();
  const [medPlanToDelete, setMedPlanToDelete] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { user } = useAuth();
  const columns = [
    "",
    t("product"),
    t("drug"),
    t("x_day"),
    t("hs_6"),
    t("hs_8"),
    t("hs_10"),
    t("hs_12"),
    t("hs_14"),
    t("hs_16"),
    t("hs_18"),
    t("hs_20"),
    t("hs_22"),
    t("hs_24"),
    t("obs"),
  ];
  const [openMedication, setOpenMedication] = React.useState(false);
  const handleCloseModal = () => {
    props.getMedicationPlans();
    setOpenMedication(false);
  }

  const openDeleteModal = (medId) => {
    setIsDeleting(true);
    setMedPlanToDelete(medId);
  }

  const deleteMed = () => {
    const url = new URL(
      "/institutions/v1/medication-plans/" + medPlanToDelete,
      dotenv.API_URL
    );
    axios
      .delete(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then(() => {
        props.getMedicationPlans();
      })
      .finally(() => setIsDeleting(false));
  }

  const styles = {
    table: {
      headers: {
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "20px",
        color: "#737589",
        textAlign: "center",
      },
      cells: {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#667085",
        textAlign: "center",
      },
    },
    title: {
      color: "#3657D9",
      fontFamily: "Poppins",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    medButton: {
      background: "#5EC360",
      width: "280px",
      height: "40px",
      color: "#FCFCFC",
    },
    deleteModal: {
      borderRadius: "16px",
      background: "#FCFCFC",
      boxShadow:
        "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
      padding: "40px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    buttons: {
      width: "144px", 
      padding: "5px",
      color: "white"
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Typography sx={styles.title}>{t("medications")}</Typography>
        <Button
          endIcon={<img src="/medicines.png" />}
          sx={styles.medButton}
          onClick={() =>
            setOpenMedication(true)
          }
        >
          {t("add_medications")}
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ background: "#F9FAFB" }}>
              {columns.map((col) => (
                <TableCell key={col} sx={styles.table.headers}>
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.medicationPlans.map((medicationPlan) => (
              <TableRow key={medicationPlan.id}>
                <TableCell padding="0">
                  <Button
                    onClick={()=>openDeleteModal(medicationPlan.id)}
                    sx={{
                      height: "21px",
                      borderRadius: "6.13px",
                      width: "21px",
                      minWidth: "0",
                    }}
                  >
                    <img src="/deleteicon.png" />
                  </Button>
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.medicine?.name} {medicationPlan.medicine?.presentation}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.medicine?.monodrug}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.totalDose}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['06:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['08:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['10:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['12:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['14:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['16:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['18:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['20:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['22:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.newSchedules['00:00'] ?? ""}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {medicationPlan.observations ?? ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddMedication
        vademecum={props.vademecum}
        patientId={props.patientId}
        open={openMedication}
        handleClose={handleCloseModal}
      />
      <Modal open={isDeleting} onClose={()=>setIsDeleting(false)} closeAfterTransition>
        <Box sx={styles.deleteModal}>
          <Box sx={styles.table.headers}>
            {t("are_you_sure_delete_medicine")}
          </Box>
          <Box display={"flex"} justifyContent={"space-evenly"} mt={"20px"}>
            <Button onClick={()=>setIsDeleting(false)} sx={[styles.buttons, {backgroundColor:"#D04444"}]}>{t("no")}</Button>
            <Button onClick={deleteMed} sx={[styles.buttons, {backgroundColor:"#5EC360"}]}>{t("yes")}</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default MedicationPlanData;
