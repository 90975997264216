import * as React from "react";
import MaterialTable from "../../components/MaterialTable";
import { Button, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { useTranslation } from "react-i18next";

const InstitutionsIndexPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = React.useMemo(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: t("name"),
        size: 240,
        Cell: (cell) => (
          <Grid spacing={1} container>
            <Grid xs={3}>
              <Avatar
                alt="avatar"
                src={cell.row.original?.avatar?.url}
                sx={{
                  width: "50px",
                  height: "50px",
                  border: 4,
                  backgroundColor: "#D9D9D9",
                  borderColor: "white",
                }}
              />
            </Grid>
            <Grid xs={9} sx={{ mt: 2 }}>
              {cell.row.original.name}
            </Grid>
          </Grid>
        ),
      },
      {
        id: "business_name",
        accessorKey: "business_name",
        header: t("business_name"),
        key: "business_name",
      },
      {
        id: "business_email",
        accessorKey: "business_email",
        header: "Correo electrónico",
      },
      {
        id: "contact-name",
        accessorKey: "contact.fullname",
        header: t("reference_contact"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "phone",
        accessorKey: "phones.0.number",
        header: t("phone"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 250,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) =>
          ["superadmin", "admin-customer"].includes(props.role) ? (
            <>
              {}
              <Button
                variant="contained"
                endIcon={<img src={"/useradd.png"} alt={"administrativos"} />}
                sx={{
                  width: "75%",
                  p: 1,
                  bgcolor: "#3988E3",
                  color: "#FCFCFC",
                }}
                onClick={() =>
                  navigate(`/institutions/${cell.row.original.id}/admins`, {
                    state: {
                      name: [cell.row.original.name],
                    },
                  })
                }
              >
                {t("administrativo")}
              </Button>
            </>
          ) : props.role == "admin-institution" ? (
            <>
              <Tooltip title="Personalizar parámetros" placement="top">
                <IconButton
                  onClick={() =>
                    navigate(`/institutions/${cell.row.original.id}/params`, {
                      state: {
                        name: [cell.row.original.name],
                      },
                    })
                  }
                  aria-label="edit"
                >
                  <img
                    src="/params.png"
                    alt="parameters"
                    style={{ width: 20, height: 20 }}
                  />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <></>
          ),
      },
    ],
    [navigate, t]
  );

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsRefetching(true);
      setIsError(false);

      const url = new URL("/institutions/v1/institutions", dotenv.API_URL);
      url.searchParams.set("page", `${pagination.pageIndex + 1}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("globalFilter", globalFilter ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setData(response.data.data);
          setRowCount(response.data.total);
          setIsError(false);
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
        });
    };
    fetchData();
  }, [columnFilters, globalFilter, pagination, sorting, user]);

  return (
    <div>
      <div>
        <MaterialTable
          columns={columns}
          data={data}
          title="Institutions"
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: t("error_fetching_data"),
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
      </div>
      <br />
      {["superadmin", "admin-customer"].includes(props.role) ? (
        <Button
          variant="contained"
          onClick={() => navigate("/institutions/add")}
          color="success"
        >
          {t("enrol")}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InstitutionsIndexPage;
