import * as React from "react";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

const SpecificAlertData = (props) => {
  const { t } = useTranslation();
  const [columns, setColumns] = React.useState([]);
  const columnsIcc = [
    t("name"),
    t("lastname"),
    t("age"),
    t("patology"),
    t("gender"),
    t("alarm_type"),
    t("detail"),
    t("date_time"),
    t("state"),
    t("priority"),
    t("how_i_feel"),
    t("comments"),
  ];
  const columnsHta = [
    t("name"),
    t("lastname"),
    t("age"),
    t("patology"),
    t("gender"),
    t("alarm_type"),
    t("detail"),
    t("date_time"),
    t("state"),
    t("priority"),
    t("comments"),
  ];

  const [alertDetail, setAlertDetail] = React.useState("");

  React.useEffect(() => {
    if (props.alert?.patient_pathology == 'ICC') {
      setColumns(columnsIcc)
    } else if (props.alert?.patient_pathology == 'HTA') {
      setColumns(columnsHta)
    }
    if (props.alert) {
      setAlertDetail(props.alert.detail);
    }
  }, [props]);

  const styles = {
    table: {
      headers: {
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "20px",
        color: "#737589",
        textAlign: "center",
      },
      cells: {
        whiteSpace: "pre-line",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#667085",
        textAlign: "center",
      },
    },
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ background: "#F9FAFB" }}>
            {columns.map((col) => (
              <TableCell key={col} sx={styles.table.headers}>
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={styles.table.cells}>
              {props.alert?.patient_name}
            </TableCell>
            <TableCell sx={styles.table.cells}>
              {props.alert?.patient_lastname}
            </TableCell>
            <TableCell sx={styles.table.cells}>
              {props.alert?.patient_age}
            </TableCell>
            <TableCell sx={styles.table.cells}>
              {props.alert?.patient_pathology}
            </TableCell>
            <TableCell sx={styles.table.cells}>
              {props.alert?.patient_gender}
            </TableCell>
            <TableCell sx={styles.table.cells}>{props.alert?.type}</TableCell>
            <TableCell sx={styles.table.cells}>{alertDetail}</TableCell>
            <TableCell sx={styles.table.cells}>
              {moment(props.alert?.datetime).format("DD/M/yy HH:mm")}
            </TableCell>
            <TableCell sx={styles.table.cells}>
              {props.alert?.status == 1 ? (
                <img src="/pending-status.png" />
              ) : props.alert?.status == 2 ? (
                <img src="/in-process-status.png" />
              ) : (
                <img src="/finished-status.png" />
              )}
            </TableCell>
            <TableCell sx={styles.table.cells}>
              {props.alert?.priority == 1 ? (
                <img src="/low-priority.png" />
              ) : (
                <img src="/high-priority.png" />
              )}
            </TableCell>
            {
              props.alert?.patient_pathology == 'ICC' ?
                <TableCell sx={styles.table.cells}>
                  {props.alert?.how_i_feel ? (
                    <img src={`/face${props.alert?.how_i_feel}.png`} />
                  ) : (
                    "-"
                  )}
                </TableCell> : <></>
            }
            <TableCell sx={styles.table.cells}>
              {props.alert?.comment}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default SpecificAlertData;
