import React, { useTransition } from "react";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import MaterialTable from "../../components/MaterialTable";
import { IconButton, Button, InputLabel, Modal, Box } from "@mui/material";
import withMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";

const SpecificSurveyPatientPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const [data, setData] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sendSurveyModal, setSendSurveyModal] = React.useState(false);
  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const location = useLocation();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (location.state?.patient) {
      props.setTitle([], location.state.patient);
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: t("surveys_name"),
        size: 150,
      },
      {
        id: "sendDate",
        accessorKey: "sendDate",
        header: t("shipping_date"),
        size: 100,
        Cell: (cell) => (
          <>
            <p>{moment(cell.row.original["sendDate"]).format("DD/M/yy")}</p>
          </>
        ),
      },
      {
        id: "responseDate",
        accessorKey: "responseDate",
        header: t("answer_date"),
        size: 100,
        Cell: (cell) => (
          <>
            {cell.row.original["status"] == "Completed" ? (
              cell.row.original["responseDate"] ? (
                <p>
                  {moment(cell.row.original["responseDate"]).format("DD/M/yy")}
                </p>
              ) : (
                <p>-</p>
              )
            ) : (
              <img src="/warning.png" />
            )}
          </>
        ),
      },
      {
        id: "daysFromEnrollment",
        accessorKey: "daysFromEnrollment",
        header: t("days_response_since_enrollment"),
        size: 100,
        Cell: (cell) => (
          <>
            {cell.row.original["status"] == "Completed" ? (
              cell.row.original["daysFromEnrollment"] != 0 ? (
                <p>{cell.row.original["daysFromEnrollment"]}</p>
              ) : (
                <p>-</p>
              )
            ) : (
              <img src="/warning.png" />
            )}
          </>
        ),
      },
      {
        id: "result",
        accessorKey: "result",
        header: t("results"),
        size: 50,
        Cell: (cell) => (
          <>
            {cell.row.original["status"] == "Completed" ? (
              <p>{cell.row.original["result"]}</p>
            ) : (
              <img src="/warning.png" />
            )}
          </>
        ),
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) =>
          [
            "superadmin",
            "admin-customer",
            "admin-institution",
            "doctor",
          ].includes(props.role) ? (
            cell.row.original["status"] == "Completed" ? (
              <IconButton
                onClick={() =>
                  navigate(`/patients/${id}/surveys/${cell.row.original.id}`)
                }
                aria-label="view"
              >
                <img src="/view.png" alt="view" />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => alert("Encuesta sin responder")}
                aria-label="view"
              >
                <img src="/view.png" alt="view" />
              </IconButton>
            )
          ) : (
            <></>
          ),
      },
    ],
    [navigate, t]
  );

  React.useEffect(() => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/surveys/user/${id}/active-completed`,
        dotenv.API_URL
      );
      url.searchParams.set("page", `${pagination.pageIndex + 1}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setData(response.data);
        });
    };
    fetchData();
  }, [columnFilters, globalFilter, pagination, sorting, user]);

  const sendSurveys = (templateId) => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/templates/${templateId}/assign`,
        dotenv.API_URL
      );
      axios
        .post(
          url.href,
          { users: [id] },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((response) => {
          if (response) {
            setSendSurveyModal(false);
            props.showMessage(t("survey_has_been_assigned_successfully"));
          }
        })
        .catch(() => {
          props.showMessage(
            t("an_error_has_occurred_try_later"),
            () => {},
            "error"
          );
        })
        .finally();
    };
    fetchData();
  };

  const styles = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      padding: "40px",
      transform: "translate(-50%, -50%)",
      width: "630px",
      boxShadow:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);",
      borderRadius: "16px",
      background: "#FCFCFC",
    },
    titles: {
      color: "#3988E3",
      fontSize: "22px",
      fontWeight: "400",
    },
    templates: {
      color: "#667085",
      fontSize: "12px",
      fontWeight: "600",
    },
    sendButton: {
      color: "#FCFCFC",
      background: "#3657D9",
      margin: "20px",
      width: "100px",
      fontSize: "14px",
    },
  };

  return (
    <div>
      <Button
        sx={{
          color: "#FCFCFC",
          background: "#3657D9",
          margin: "20px",
          width: "251px",
        }}
        endIcon={<img src="/plus.png" />}
        onClick={() => setSendSurveyModal(true)}
      >
        {t("add_survey")}
      </Button>
      <InputLabel sx={[styles.titles, { margin: "20px" }]}>
        <b>{t("account_history")}</b>
        {location?.state.patient}
      </InputLabel>
      <MaterialTable
        columns={columns}
        data={data}
        title="Institutions"
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: t("error_fetching_data"),
              }
            : undefined
        }
        enableColumnFilters={false}
        enableFilters={false}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
      <Modal open={sendSurveyModal} onClose={() => setSendSurveyModal(false)}>
        <Box sx={styles.modal}>
          <InputLabel sx={styles.titles}>
            <b>{t("immediate_sending_surveys")}</b>
          </InputLabel>
          <Box
            display={"flex"}
            sx={{ alignItems: "center", marginLeft: "20px" }}
          >
            <Box width={"75%"}>
              <InputLabel sx={styles.templates}>
                {t("self_care_level")}
              </InputLabel>
            </Box>
            <Box width={"25%"}>
              <Button
                sx={styles.sendButton}
                endIcon={<img src="/send_white.png" />}
                onClick={() => sendSurveys(1)}
              >
                {t("send")}
              </Button>
            </Box>
          </Box>
          <Box
            display={"flex"}
            sx={{ alignItems: "center", marginLeft: "20px" }}
          >
            <Box width={"75%"}>
              <InputLabel sx={styles.templates}>
                {t("patient_quality_life")}
              </InputLabel>
            </Box>
            <Box width={"25%"}>
              <Button
                sx={styles.sendButton}
                endIcon={<img src="/send_white.png" />}
                onClick={() => sendSurveys(2)}
              >
                {t("send")}
              </Button>
            </Box>
          </Box>
          <Box
            display={"flex"}
            sx={{ alignItems: "center", marginLeft: "20px" }}
          >
            <Box width={"75%"}>
              <InputLabel sx={styles.templates}>
                {t("medication_Adherence")}
              </InputLabel>
            </Box>
            <Box width={"25%"}>
              <Button
                sx={styles.sendButton}
                endIcon={<img src="/send_white.png" />}
                onClick={() => sendSurveys(3)}
              >
                {t("send")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default withMessages(SpecificSurveyPatientPage);
