import { IconButton, Button } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import MaterialTable from "../../../components/MaterialTable";
import axios from "axios";
import dotenv from "react-dotenv";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React from "react";
import WithMessages from "../../../components/WithMessages";
import { useTranslation } from "react-i18next";

const CustomizeSpecificSurveyIndexPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const { t } = useTranslation();
  const [templates, setTemplates] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.template) {
      props.setTitle([], location.state.template);
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        id: "institution",
        header: t("institution"),
        key: "institution",
        accessorKey: "institution",
        size: 200,
      },
      {
        id: "medicalGroup",
        accessorKey: "medical_group_name",
        header: t("medicalGroup"),
        key: "medicalGroup",
        size: 200,
      },
      {
        id: "sendFrequency",
        accessorKey: "send_frequency",
        header: t("send_frequency"),
        key: "sendFrequency",
        size: 150,
        Cell: (cell) => (
          <>
            {cell.row.original.send_frequency}
            {cell.row.original.send_frequency_type === 1
              ? " Días"
              : cell.row.original.send_frequency_type === 2
              ? " Semanas"
              : " Meses"}
          </>
        ),
      },
      {
        id: "responsePeriod",
        accessorKey: "response_period",
        header: t("answer_time"),
        key: "responsePeriod",
        size: 150,
        Cell: (cell) => (
          <>
            {cell.row.original.response_period}
            {cell.row.original.response_period_type === 1
              ? t("days")
              : cell.row.original.response_period_type === 2
              ? t("weeks")
              : t("months")}
          </>
        ),
      },
      {
        id: "others",
        accessorKey: "others",
        header: t("others"),
        key: "others",
        size: 150,
        Cell: (cell) => (
          <>
            {cell.row.original.time_after_enrollment
              ? `${t("send_to")} ${cell.row.original.time_after_enrollment} ${
                  cell.row.original.time_after_enrollment_type === 1
                    ? t("days")
                    : cell.row.original.time_after_enrollment_type === 2
                    ? t("weeks")
                    : t("months")
                } desde el enrolamiento.`
              : t("moment_enrollment")}
          </>
        ),
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 100,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) =>
          [
            "superadmin",
            "admin-customer",
            "admin-institution",
            "doctor",
          ].includes(props.role) ? (
            <>
              <IconButton
                onClick={() =>
                  navigate(`./${cell.row.original.id}`, {
                    state: {
                      template: location.state.template,
                      institution: {
                        id: cell.row.original.institution_id,
                        name: cell.row.original.institution,
                      },
                      medicalGroup: {
                        id: cell.row.original.medical_group_id,
                        name: cell.row.original.medical_group_name,
                      },
                      form: {
                        frequencyType: cell.row.original.send_frequency_type,
                        frequencyNumber: cell.row.original.send_frequency,
                        answerPeriodType:
                          cell.row.original.response_period_type,
                        answerPeriodNumber: cell.row.original.response_period,
                        sendOption: cell.row.original.time_after_enrollment
                          ? 2
                          : 1,
                        timeAfterEnrollmentType:
                          cell.row.original.time_after_enrollment_type,
                        timeAfterEnrollmentNumber:
                          cell.row.original.time_after_enrollment,
                      },
                    },
                  })
                }
                aria-label="view"
              >
                <img src="/edit.png" alt="view" />
              </IconButton>
              <IconButton
                aria-label="view"
                onClick={() => {
                  deleteConfiguration(cell.row.original.id);
                }}
              >
                <img src="/delete.png" alt="view" />
              </IconButton>
            </>
          ) : (
            <></>
          ),
      },
    ],
    [navigate, t]
  );

  const deleteConfiguration = (configurationId) => {
    setIsLoading(true);
    const url = new URL(
      `/institutions/v1/surveys/configuration/${configurationId}`,
      dotenv.API_URL
    );
    axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then(() => {
        setIsLoading(false);
        setIsError(false);
        navigate(0);
      });
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/surveys/configuration/${id}`,
        dotenv.API_URL
      );
      url.searchParams.set("page", `${pagination.pageIndex + 1}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("globalFilter", globalFilter ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setTemplates(response.data.data);
        });
    };
    fetchData();
  }, [columnFilters, globalFilter, pagination, sorting, user]);

  return (
    <div>
      <Button
        sx={{
          color: "white",
          background: "#3657D9",
          borderRadius: "15px",
          m: "20px 0px",
          width: "200px",
        }}
        endIcon={<img src="/plus.png" />}
        onClick={() =>
          navigate("/surveys/customize-surveys/multiple", {
            state: {
              selectedTemplates: [id],
            },
          })
        }
      >
        {t("add")}
      </Button>
      <MaterialTable
        columns={columns}
        data={templates}
        title={t("surveys")}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: t("error_fetching_data"),
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
    </div>
  );
};
export default WithMessages(CustomizeSpecificSurveyIndexPage);
